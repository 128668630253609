.leftAdds {
    
    width: 200px;

    div {
        color: var(--green);
        width: 100%;
        height: 200px;
        display: block;
        text-align: right;
        //display: flex;
        //justify-content: center;
        //align-items: center;
        margin-bottom: 1.8rem;

        a {
            height: 200px;

            img {
                width: 200px;
                height: 100%;
                
            }
        }

        .listSocial {

            li {
                display: inline-block;
                padding-left: 0.5rem;
            }
            
        }

        
    }
}

/*Responsivo*/
@media (min-width: 992px) and (max-width: 1009px) {
    .leftAdds { 

        width: 175px; 
        
        div {
            height: 175px;

            a {
                height: 175px;
    
                img {
                    width: 175px;
                    height: 100%;
                    
                }
            }
        }
    }  
}

@media (max-width: 991px) {
    .leftAdds {display: none;}
}