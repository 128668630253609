.header {
    padding: 1rem 0;

    .headerMenu {
        display: flex;
        justify-content: space-between;

        .logo {
            width: 19rem;

            a {
                display: block;

                img {
                    width: 100%;
                }
            }
           
        }

        .headerContent {
            display: flex;
            flex-direction: column;
            gap: 15px;
            justify-content: flex-end;
            padding-bottom: 20px;

            ul {
                display: flex;
                align-self: center;
                gap: 35px;

                li {
                    cursor: pointer;
                    position: relative;
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: 700;
                    color: var(--blue);

                    &:hover {
                        color: var(--green);

                        svg {
                            color: var(--green) !important;
                        }
                    }

                    svg {              
                        position: relative;
                        top: 2px;
                        left: 0;
                    }

                    .submenu {
                        display: block;
                        position: absolute;
                        background: var(--white);
                        width: 15rem;
                        padding: 0rem 0.5rem;
                        top:23px;
                        z-index: 3;
                        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);

                        li {
                            color: var(--blue);
                            font-size: 0.7rem;
                            padding: 0.2rem 0;

                            &:hover {
                                color: var(--green);

                                svg{
                                    color: var(--green) !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .mobile {
        display: none;
    }

    .menuMobile {
        display: none;
    }

    .searchServiceMobile {
        display: none;
    }
}


/*Responsivo*/
@media (max-width: 991px) {
    .header {

        .mobile {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            padding-bottom: 1rem;

            .logo {
                width: 10rem;
    
                a {
                    display: block;
    
                    img {
                        width: 100%;
                    }
                }
               
            }
        }

        .searchServiceMobile {
            display: block;
        }

        .headerMenu {
            display: none;
        }

        .menuMobile {
            display: block;

            ul {
                text-align: right;

                li {
                    cursor: pointer;
                    position: relative;
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: 700;
                    color: var(--blue);
                    line-height: 2rem;
    
                    &:hover {
                        color: var(--blue);
    
                        svg {
                            color: var(--blue) !important;
                        }
                    }
    
                    svg {              
                        position: relative;
                        top: 2px;
                        left: 0;
                    }
    
                    .submenu {
                        display: block;
                        position: absolute;
                        right: 0;
                        background: var(--white);
                        width: auto;
                        padding: 0rem 0.5rem;
                        top:28px;
                        z-index: 3;
                        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
    
                        li {
                            color: var(--blue);
                            font-size: 0.7rem;
                            padding: 0rem 0;
                            line-height: 1.3rem;
    
                            &:hover {
                                color: var(--blue);
    
                                svg{
                                    color: var(--blue) !important;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}
