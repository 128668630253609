.listaTelefonia {
  color: var(--blue);
  
  form {
      display: grid;
      grid-template-columns: 1fr 1fr 140px;
      gap: 10px;

      label {
          font-size: 1.3rem;
          font-weight: bold;
          animation-name: pulse_animation;
          animation-duration: 5s;
          transform-origin: 70% 70%;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
      }

      input {
          border: 1px solid var(--blue);
          padding-left: 0.5rem;
          color: var(--blue);
          &::-webkit-input-placeholder {color: var(--blue);}
          &:-ms-input-placeholder {color: var(--blue);}
          &::placeholder {color: var(--blue);}
      }

      button {
          border-radius: 0;
          border: none;
          background: var(--green);
          color: var(--blue);
          transition: background 0.2s;
          font-size: 1.3rem;
          font-weight: 700;
          text-transform: uppercase;

          &:hover {
              background: var(--dark-green);
          }
      }

      
  }
}

/*Responsivo*/
@media (max-width: 991px) {

  .listaTelefonia {
    color: var(--blue);
    
    form {
        grid-template-columns: 1fr;

        input, button { 
            height: 2.2rem;
        }
    }
  }
  
}