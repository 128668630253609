@keyframes pulse_animation {
    0% { transform: scale(1); }
    30% { transform: scale(1); }
    40% { transform: scale(1.08); }
    50% { transform: scale(1); }
    60% { transform: scale(1); }
    70% { transform: scale(1.05); }
    80% { transform: scale(1); }
    100% { transform: scale(1); }
}

.iframeWebRadio {
    background: var(--green);
    border: 1px solid var(--blue);
    color: var(--blue);
    font-weight: 700;

    .infoRadio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.8rem;

        span {
            animation-name: pulse_animation;
            animation-duration: 5s;
            transform-origin: 70% 70%;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            font-size: 0.9rem;
        }

        svg {              
            position: relative;
            top: 4px;
            left: 0;
        }


    }
}

.topBar {
    background: var(--green);
    border-bottom: 1px solid var(--blue);
    color: var(--blue);
    font-weight: 700;
    
    
    

    .topBarContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.8rem;
        padding: 1.2rem 0;
        font-size: 0.9rem;

        ul {
            display: flex;
            align-self: center;
            gap: 20px;

            li {
                cursor: pointer;
                position: relative;

                svg {              
                    position: relative;
                    top: 2px;
                    left: 0;
                }

                .submenu {
                    display: block;
                    position: absolute;
                    background: var(--white);
                    width: 10rem;
                    padding: 0rem 0.5rem;
                    top:22px;
                    z-index: 2;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);

                    li {
                        color: var(--blue);
                        font-size: 0.7rem;
                        padding: 0.2rem 0;
                        text-transform: uppercase;

                        &:hover {
                            color: var(--blue);

                            svg{
                                color: var(--blue) !important;
                            }
                        }
                    }
                }

                form {
                    display: flex;

                    input {
                        border: 1px solid var(--blue);
                        padding-left: 0.5rem;
                        color: var(--blue);
                        &::-webkit-input-placeholder {color: var(--blue);}
                        &:-ms-input-placeholder {color: var(--blue);}
                        &::placeholder {color: var(--blue);}
                    }
                     
                    button {
                        width: 6rem;
                        border-radius: 0;
                        border: none;
                        background: var(--blue);
                        color: var(--white);
                        font-weight: 700;
                        transition: background 0.2s;
                        text-transform: uppercase;

                        &:hover {
                            background: var(--light-blue);
                        }
                    }

                }
            }
        }

    }
}

/*Responsivo*/
@media (max-width: 991px) {
    .iframeWebRadio {

        .infoRadio {
            flex-direction: column;
            height: auto;
            gap: 10px;
            margin-bottom: 0.8rem;
            text-align: center;
        }
    }

    .topBar {
        .topBarContent {
            flex-direction: column;
            padding: 0.8rem 0;
            gap: 10px;
            height: auto;
            text-align: center;
            //margin-bottom: 0.8rem;

            ul {
                align-items: center;
                gap: 3px;

                form {

                    input, button { 
                        height: 2.2rem;
                    }
                }
            }

            .hideMobile {
                display: none ;
            }
        
        }
    }
}