.webRadioBanner {
    padding: 1.3rem 0 0 0;

    h1 {
        font-size: 1.3rem;
        padding-bottom: 1rem;
    }
    
    img {
        width: 100%;
    }
}