#areaCliente {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  
    .content {
        //width: 810px;
        width: 590px;
    }
}


.AreaClienteContent {

    h1 {
      font-size: 1.3rem;
      padding-bottom: 1rem;
    }

    .Header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;

        svg {
            position: relative;
            top: 2px;
        }
    }

    .Servicos {
        
        h2 {
            font-size: 1rem;
            padding-bottom: 1rem;
        }

        a, span {
            font-size: 0.9rem;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
        }

        .service {

            .FirstSpan {
                display: block;
            }
            
            border: 1px dashed var(--blue);
            padding: 0.5rem;
            margin-bottom: 1rem;

            p {
                font-size: 0.8rem;
                text-align: center;
            }

            .serviceInfo {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 10px;

            
            }
        }
    }
}