.Middle {
    padding: 1rem 0 0 0;

    h1 {
        font-size: 1.3rem;
        padding-bottom: 1rem;
    }

    .slideContent {
        width: 196px !important;
    }

    
    .slide {
        padding: 0 0.1rem;
        width: 196px;
        height: 230px;
        text-align: right;

        a {

            display: block;

            img {
                object-fit: fill;
                width: 100%;
                height: 196px;
            }
        }

        .listSocial {

            li {
                display: inline-block;
                padding-left: 0.5rem;
            }
            
        }


    }
    
}


