.slide {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    height: 200px;

    .slideImg {
        width: 200px;
        //border: 1px solid var(--blue);
        color: var(--green);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 200px;
            height: 100%;
        }
    }

    .slideContent {
        width: 56.5625rem;
        //border: 1px solid var(--blue);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-transform: uppercase;

        h1 {
           color: var(--blue);
           font-size: 1rem;
           font-weight: bold;
           padding-bottom: 1.5rem;
        }

        p {
            color: var(--blue);
            font-size: 0.8rem;
        }
    }
}

/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
    .slide {
        .slideContent {
            width: 45.3125rem;
        }
    }
}

@media (min-width: 1200px) and (max-width: 1399px){
    .slide {
        .slideContent {
            width: 32.8125rem;
        }
    }
}

@media (max-width: 991px) { 

    .slide {
        margin: 0rem 0 2rem 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: auto;

        .slideContent {
            width: 100%;
            text-align: center;

            h1 {
                padding: 0.8rem 0;
            }

        
        }
    }
}