.HorarioOnibus {
    padding: 1rem 0 0 0;

    h1 {
        font-size: 1.3rem;
        padding-bottom: 1rem;
    }
    
    .slide {
        padding: 0 0.1rem;
        height: 100px;

        a {

            display: block;

            img {
                object-fit: fill;
                width: 100%;
                height: 100px
            }
        }


    }
}

@media (max-width: 991px) {
    .HorarioOnibus {

        .slideOnibus {
            width: 100%;
            
            .slide {
                padding: 0 0.1rem;
                height: auto;
        
                a {
        
                    display: block;
        
                    img {
                        object-fit: fill;
                        width: 100%;
                        height: 100%
                    }
                }
        
        
            }
        }

       
    }
}