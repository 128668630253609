#servicos {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .content {
      //width: 810px;
      width: 590px;
  }
}

.ServicosContent {

  h1 {
    font-size: 1.3rem;
    padding-bottom: 1rem;
  }

  div {
    font-size: 1rem;
    border: 1px dashed var(--blue);
    padding: 1rem 0.5rem 0 0.5rem;
    margin-bottom: 1rem;

    .description {
      border: none;
      padding: 0;
      margin-bottom: 0;
    }

    span {
      padding-bottom: 1rem;
      display: block;
    }

    p {
      display: block;
      margin: 0.5rem 0;
      font-size: 0.8rem;
    }

    .contato {
      display: flex;
      flex-direction: column;
      border: none;
      padding: 0;
      margin-bottom: 0;

      span {
        font-size: 0.8rem;
        padding-bottom: 0.5rem;

        svg {              
          position: relative;
          top: 5px;
          left: 0;
        }
      }
    }
  }
}

/*Responsivo*/
@media (max-width: 991px) {
  #servicos {

    .content {
        width: 100%;
    }

  }
}