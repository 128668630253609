#quemsomos {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .content {
      //width: 810px;
      width: 590px;
  }
}

.quemsomosContent {

  h1 {
    font-size: 1.3rem;
    padding-bottom: 1rem;
  }

  div {
    font-size: 1rem;
  }
}

/*Responsivo*/
@media (max-width: 991px) {
  #quemsomos {

    .content {
        width: 100%;
    }

  }
}