@keyframes pulse_animation {
    0% { transform: scale(1); }
    30% { transform: scale(1); }
    40% { transform: scale(1.08); }
    50% { transform: scale(1); }
    60% { transform: scale(1); }
    70% { transform: scale(1.05); }
    80% { transform: scale(1); }
    100% { transform: scale(1); }
}

#home {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .content {
        //width: 810px;
        width: 590px;
    }
}

.videoDestaque {

    padding: 1rem 0 0 0;    

    h1 {
        font-size: 1.3rem;
        padding-bottom: 1rem;
    }

    iframe {
        height: 315px;
        //height: 400px;
    }

    p {
        font-size: 0.8rem;
    }

}

#parceiros {
    padding: 0.2rem 0;
}

.mobileAdds {
    display: none;
}

/*Responsivo*/
@media (max-width: 991px) {
    #home {

        .content {
            width: 100%;
        }

    }

    .middleAdds {
        display: none;
    }

    .mobileAdds {
        display: block;
    }
}