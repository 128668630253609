.Parceiros {
    padding: 1rem 0 0 0;

    h1 {
        font-size: 1.3rem;
        padding-bottom: 1rem;
    }

    
    .slide {
        padding: 0 0.1rem;
        height: 140px;
        text-align: right;

        a {

            display: block;

            img {
                object-fit: fill;
                width: 100%;
                height: 100px
            }
        }

        .listSocial {

            li {
                display: inline-block;
                padding-left: 0.5rem;
            }
            
        }


    }
    
}

@media (max-width: 991px) {
    .Parceiros {

        .slideParceiros {
            width: 100%;
            
            .slide {
                padding: 0 0.1rem;
                height: auto;
        
                a {
        
                    display: block;
        
                    img {
                        object-fit: fill;
                        width: 100%;
                        height: 100%
                    }
                }
        
        
            }
        }

       
    }
}
