.slide {
    margin: 0 0 1rem 0;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    height: 90px;
    background-repeat: no-repeat;
    background-size: cover;
}

/*Responsivo*/
@media (max-width: 991px) {
    .slide {
        background-size: 100%;
    }
}

@media (max-width: 480px) {
    .slide {
        height: 60px;
    }
}