.modalBG{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; 
  background: rgba(0, 0, 0, 0.6); 
  z-index: 99999; 
  display: none;

  &.showModal{
    display: block; 
    overflow-y: auto;
  }

  .modal{
    position: relative; 
    top: 20px; 
    width: 500px; 
    height: auto; 
    background: var(--white); 
    border: 1px solid var(--green); 
    margin: 0 auto;

    .modalContent {
      margin: 0 10px;

      .closeIcon {
        position: absolute;
        right: 1px;
        cursor: pointer;

        path {
          stroke: var(--green);
        }
      }

      p {
        padding: 3rem 0 1rem 0;
      }

      form {

        div {
          
          margin-bottom: 30px!important;
          position: relative;

          input {
            padding-left: 1.5625rem;
            z-index: 2;
            position: relative;
            border: 0;
            border-bottom: 1px solid var(--green);
            width: 100%;
            &::-webkit-input-placeholder {color: var(--green);}
            &:-ms-input-placeholder {color: var(--green);}
            &::placeholder {color: var(--green);}
            color: var(--blue);

            &:focus {
              border-color: var(--blue);
              &::-webkit-input-placeholder {color: var(--blue);}
              &:-ms-input-placeholder {color: var(--blue);}
              &::placeholder {color: var(--blue);}
            }

            &:focus + label {
              svg {
                color: var(--blue) !important;
              }
            }

            &:not(:placeholder-shown) + label {
              
              svg {
                color: var(--blue) !important;
              }
            }

            &:not(:placeholder-shown) {
              border-color: var(--blue);
            }

          }

          select {
            padding-left: 1.5625rem;
            z-index: 2;
            position: relative;
            border: 0;
            border-bottom: 1px solid var(--green);
            width: 100%;
            color: var(--green);

            &:focus {
              border-color: var(--blue);
              color: var(--blue);
            }

            &:focus + label {
              svg {
                color: var(--blue) !important;
              }
            }

            &.Focus {
              color: var(--blue);
              border-bottom: 1px solid var(--blue);
            }

            &.Focus + label {
              svg {
                color: var(--blue) !important;
              }
            }
            
          }

          textarea {
            padding-left: 0.5rem;
            z-index: 2;
            position: relative;
            border: 1px solid var(--green);
            width: 100%;
            height: 5rem;
            color: var(--green);

            &::-webkit-input-placeholder {color: var(--green);}
            &:-ms-input-placeholder {color: var(--green);}
            &::placeholder {color: var(--green);}

            &:focus {
              border-color: var(--blue);
              color: var(--blue);
              &::-webkit-input-placeholder {color: var(--blue);}
              &:-ms-input-placeholder {color: var(--blue);}
              &::placeholder {color: var(--blue);}
            }

            &:not(:placeholder-shown) {
              
              color: var(--blue) !important;
              border-color: var(--blue);
              
            }
          }

          label {
            display: block;
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translateY(-40%);
            left: 0;
            z-index: 2;
          }
        
        }

        button {
          width: 100%;
          height: 3rem;
          margin-bottom: 10px;
          background: var(--green);
          color: var(--white);
          text-transform: uppercase;
          border: none;
          transition: background 0.2s;

          &:hover {
            background: var(--dark-green);
          }
        }

        .TogglePassword {
          position: absolute;
          right: 0;
          left: 95%;

          &:hover {
             svg {
               color: var(--blue) !important;
             }
          }
        }

      }

      .sucesso {
        text-align: center;
        padding-bottom: 12px;

        svg {
          position: relative;
          top: 2px;
        }
      }
    }
  }
}

/*Responsivo*/
@media (max-width: 480px) {
  .modalBG{
    .modal{
      width: 300px;

      .modalContent {

        p {
          font-size: 0.8rem;
        }

        form {
          .TogglePassword {
            position: absolute;
            right: 0;
            left: 92%;
          }
        }
      }
    }
  }
}