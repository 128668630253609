#faleconosco {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .content {
      //width: 810px;
      width: 590px;
  }
}

.faleconoscoContent {

  h1 {
    font-size: 1.3rem;
    padding-bottom: 1rem;
  }

  p {
    font-size: 0.8rem;
    padding-bottom: 0.8rem;
  }

  form {

    div {
      
      margin-bottom: 30px!important;
      position: relative;

      input {
        padding-left: 1.5625rem;
        z-index: 2;
        position: relative;
        border: 0;
        border-bottom: 1px solid var(--green);
        width: 100%;
        &::-webkit-input-placeholder {color: var(--green);}
        &:-ms-input-placeholder {color: var(--green);}
        &::placeholder {color: var(--green);}
        color: var(--blue);

        &:focus {
          border-color: var(--blue);
          &::-webkit-input-placeholder {color: var(--blue);}
          &:-ms-input-placeholder {color: var(--blue);}
          &::placeholder {color: var(--blue);}
        }

        &:focus + label {
          svg {
            color: var(--blue) !important;
          }
        }

        &:not(:placeholder-shown) + label {
          svg {
            color: var(--blue) !important;
          }
        }

        &:not(:placeholder-shown) {
          border-color: var(--blue);
        }

      }

      select {
        padding-left: 1.5625rem;
        z-index: 2;
        position: relative;
        border: 0;
        border-bottom: 1px solid var(--green);
        width: 100%;
        &::-webkit-input-placeholder {color: var(--green);}
        &:-ms-input-placeholder {color: var(--green);}
        &::placeholder {color: var(--green);}
        color: var(--green);

        &:focus {
          border-color: var(--blue);
         color: var(--blue);
        }

        &:focus + label {
          svg {
            color: var(--blue) !important;
          }
        }

        &.Focus {
          color: var(--blue);
          border-bottom: 1px solid var(--blue);
        }

        &.Focus + label {
          svg {
            color: var(--blue) !important;
          }
        }

      }

      textarea {
        padding: 1rem;
        z-index: 2;
        position: relative;
        border: 0;
        border: 1px solid var(--green);
        width: 100%;
        &::-webkit-input-placeholder {color: var(--green);}
        &:-ms-input-placeholder {color: var(--green);}
        &::placeholder {color: var(--green);}
        color: var(--green);

        &:focus {
          border-color: var(--blue);
          color: var(--blue);
          &::-webkit-input-placeholder {color: var(--blue);}
           &:-ms-input-placeholder {color: var(--blue);}
          &::placeholder {color: var(--blue);}
        }

        &:not(:placeholder-shown) {
              
          color: var(--blue) !important;
          border-color: var(--blue);
          
        }

      }

      label {
        display: block;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-40%);
        left: 0;
        z-index: 2;
      }
    
    }

    button {
      width: 100%;
      height: 3rem;
      margin-bottom: 10px;
      background: var(--green);
      color: var(--white);
      text-transform: uppercase;
      border: none;
      transition: background 0.2s;

      &:hover {
        background: var(--dark-green);
      }
    }

  }

  .sucesso {
    text-align: center;
    padding-bottom: 12px;

    svg {
      position: relative;
      top: 2px;
    }
  }

  .footer {
    padding: 0.8rem 0;
  }
}

/*Responsivo*/
@media (max-width: 991px) {
  #faleconosco {

    .content {
        width: 100%;
    }

  }
}