*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
}

:root {
    --white: #ffffff;
    --green: #00a651;
    --dark-green: #037d3f;
    --light-blue: #5054dc;
    --blue:#2e3192;
}

@media (max-width: 1080px) {
    html {
        font-size: 93.75%;
    }
}

@media (max-width: 720px) {
    html {
        font-size: 87.5%;
    }
}
  

body{
    color: var(--blue);
    background-color: var(--white);
    font-family: "Arial Black", sans-serif;
}

body, input, textarea, select, button {
    font: 400 1rem "Arial Black", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    font-family: "Arial Black", sans-serif;
    color: var(--blue);
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.5rem;
}

button {
    cursor: pointer;
}

a {
    color: inherit;
    text-decoration: none;
}

ul {
    list-style: none;
}

.container {
    max-width: 1010px;
    margin: 0 auto;
    //padding: 0 10px;
}

.swiper-pagination-bullet-active{background: var(--blue) !important;}
.swiper-pagination-bullet{background: var(--blue) !important;}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -5px;
}

//Pagination
.pagination {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 2rem 0 0 0;
    color: var(--blue);
    font-size: 0.9rem;
}

.pagination li {
    border: none;
    padding: 0.25rem;
    min-width: 2.75rem;
    height: 2.75rem;
    line-height: 2.5rem;
    text-shadow: none;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    a {
      display: block;
      width: 40px;
      height: 40px;
      text-align: center;
      &:focus {
        outline: none;
      }
    }
}

.pagination__link--active{
    background: var(--green);
    color: var(--blue);
    font-weight: 700;
}

.pagination .previous, .next {
    font-weight: 700;
    cursor: pointer;
    
    a {
      &:focus {
        outline: none;
      }
    }
    
}

.cancelar {
    background: var(--green);
    color: var(--white);
    border: 0;
    margin: 10px;
    padding: 10px;
}

.deletar {
    background: var(--blue);
    color: var(--white);
    border: 0;
    margin: 10px;
    padding: 10px;
}

/*Responsivo*/
@media (min-width: 992px) and (max-width: 1009px) {
    .container {
        max-width: 960px;
        margin: 0 auto;
    }
}

@media (max-width: 991px) {
    .container {
        max-width: 100%;
        margin: 0 10px;
    }

    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0px;
    }
}